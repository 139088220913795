<template>
	<section class="containter-fluid" id="container_main">
		<section class="container p-4">
			<h1 class="text-weight-bolder text-weight-thin" >Token Expirado</h1>
			<p>Este link expirou e não é mais válido.</p>
			<p>Está tentando confirmar sua aula de boas vindas? Fale com a nossa central de atendimento pelo fone: <strong>0800 000 0301</strong></p>
		</section>
	</section>
</template>

<script>
export default {
    name: 'tokenExp',
}
</script>

<style scoped>

</style>

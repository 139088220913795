<template>
  <vs-row vs-w="12">
    <vs-row
      v-if="confirm == 'S' && shipping == '1'"
      vs-align="center"
      vs-type="flex"
      vs-justify="space-around"
      vs-w="12"
    >
      <vs-row
        vs-align="center"
        vs-type="flex"
        vs-justify="space-around"
        vs-w="12"
      >
        <vs-col
          vs-lg="8"
          vs-xs="12"
          vs-sm="10"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
        >
          <h1>Agradecemos pela confirmação!</h1>
        </vs-col>
      </vs-row>

      <vs-row class="container p-4">
        <vs-row vs-w="12">
          <vs-col vs-xs="12" vs-w="6">
            <h4>Prezado(a) aluno(a)</h4>
            <p>Que legal ter você conosco!</p>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col vs-xs="12" vs-w="6">
            <p>
              Obrigado por escolher a
              <strong>QI Faculdade & Escola Técnica.</strong>
            </p>
            <p>Estamos imensamento felizes de tê-lo(a) aqui conosco!</p>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col vs-xs="12" vs-w="6">
            <p>
              Para mais informações, como por exemplo cronograma das aulas,
              links para acesso às aulas online(quando for o caso), entre
              outros, acesse nosso
              <a href="https://portalaluno.qi.edu.br">Portal do Aluno.</a>
              <span class="links"> Te esperamos de braços abertos! </span>
            </p>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col vs-xs="12" vs-w="6">
            <p>
              <big>Equipe QI Faculdade & Escola Técnica</big>
              <span class="links">
                O mercado RECONHECE, VALORIZA e CONTRATA quem faz QI!
              </span>
            </p>
          </vs-col>
        </vs-row>
      </vs-row>
    </vs-row>

    <vs-row class="container p-4" v-else-if="error != null">
      <vs-row>
        <vs-col>
          <h1>Ocorreu um erro ao confirmar a presença.</h1>
        </vs-col>
        <vs-col>
          <h2>Por favor entre em contato conosco!</h2>
        </vs-col>
        <vs-col>
          <p>Telefone para contato: 0800 000 0301</p>
        </vs-col>
      </vs-row>
    </vs-row>

    <vs-row
      v-if="confirm == 'N' && shipping == null"
      class="conteudo"
      vs-type="flex"
      vs-justify="space-around"
    >
      <vs-row vs-justify="space-around" vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="10">
          <h3>
            Conte-nos abaixo por que não poderá comparecer a aula de boas vindas
            e depois clique em "enviar".
          </h3>
        </vs-col>
        <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col vs-w="8" vs-lg="8" vs-xs="10" vs-sm="10">
            <vs-textarea
              height="210px"
              rows="10"
              v-model="message"
              counter="500"
              :counter-danger.sync="counterDanger"
              maxlength="500"
              autofocus
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col vs-w="8">
            <vs-button :disabled="!message.length" @click="send(message)">
              Enviar
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-row>
    </vs-row>
    <vs-row
      class="container p-4"
      vs-w="12"
      v-if="shipping != null && confirm == 'N'"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
        v-if="shipping == '1'"
      >
        <h2>Obrigado por nos informar, mensagem enviada!</h2>
      </vs-col>
      <vs-col v-else>
        <h2>
          Ocorreu um erro ao enviar a mensagem por favor entre em contato pelo
          fone 0800 000 0301
        </h2>
      </vs-col>
    </vs-row>
  </vs-row>
</template>

<script>
import jwt from "../middlewares/token.middleware";
import getClass from "../services/api";
import sendMessage from "../services/api";

export default {
  name: "confirm",
  data: () => ({
    token: null,
    message: "",
    confirm: null,
    source: null,
    link: null,
    error: null,
    shipping: null,
    result: null,
  }),
  mounted() {
    this.token = jwt.verifyToken(this.$route.params.token);
    let paramConfirm = this.$route.params.confirm.toUpperCase();
    this.source = this.$route.params.source;

    if (paramConfirm == "S") {
      this.confirm = paramConfirm;
      this.message = "O aluno confirmou a presença!";
      this.send();
    } else if (paramConfirm == "N") {
      this.confirm = paramConfirm;
    } else {
      this.error = "Erro ao acessar essa página, link inválido.";
      console.log("erro");
    }

    new getClass()
      .getLinks(this.token.idturmadisc)
      .then(({ data }) => {
        this.link = Array.isArray(data) ? data : [data];
        console.log(this.link);
      })
      .catch(({ error }) => {
        this.error = error;
      });
  },

  methods: {
    send() {
      this.$vs.loading({
        color: "danger",
      });
      new sendMessage()
        .sendMessage(
          this.$route.params.token,
          this.confirm,
          this.message,
          this.source
        )
        .then((result) => {
          this.shipping = result.data.result;
          this.result = Array.isArray(result) ? result : [result];
          console.log(this.shipping);

          this.message = " ";
        })
        .catch((response) => {
          if (response && response.data) {
            this.error = response.data.error;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style>
h1,
.result {
  padding-top: 0.5em;
}

.vs-textarea {
  outline: 1px solid #797d7f;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text {
  padding-top: 2em;
}

.conteudo {
  padding-top: 3em;
  padding-left: 1.5em;
}

@media screen and (max-width: 1318px) {
  h1 {
    font-size: 2em;
  }
}

@media screen and (max-width: 1054px) {
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 760px) {
  .container {
    max-width: 1000px !important;
  }
}

@media screen and (max-width: 680px) {
  h1 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 530px) {
  h1 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 395px) {
  h1 {
    font-size: 1.2em;
  }
}
</style>

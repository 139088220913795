import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import VueRouter from 'vue-router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "material-design-icons/iconfont/material-icons.css"
import 'vuesax/dist/vuesax.css'


import TokenExp from './pages/TokenExpired.vue'
import Confirm from './pages/Confirm.vue'


Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#9B0E1C',
    } 
  }
})

Vue.use(VueRouter)

const router = new VueRouter ({
  mode: 'history',
  routes: [
    {
      path: '/confirm/:token/:source/:confirm',
      component: Confirm
    },
    {
      path: '/tokenexp',
      component: TokenExp,
    },
    {
      path: '/',
      component: TokenExp,
    }
  ]
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

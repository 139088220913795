<template>
	<section class="containter-fluid bg-vermelho">
		<section class="container p-4">
			<nav class="row g-2">
				<div class="col  center_itens logo">
					<img src="@/assets/qi-faculdade-e-escola-tecnica-negativa.svg" class=" img-fluid"/>
				</div>
			</nav>
		</section>
	</section>
</template>

<script>
import jwt from '../middlewares/token.middleware'

export default {
    name: 'componentHeader',
	data: () => ({
		timeExp: null,
		countdown: null
	}),
	mounted(){
		console.log(this.$route.params.token)
		let token = jwt.verifyToken( this.$route.params.token )
		if( token.error ) {
			this.$router.push('/tokenExp')
		}
	},
}
</script>

<style scoped>

</style>
import axios from 'axios'

export const https = (baseURL = '', token) => {
  const instance = axios.create({
    /*baseURL:`https://matricula.qi.edu.br:9090/api${baseURL}`,*/
    /*baseURL: `http://localhost:9090/api${baseURL}`,*/
	baseURL:`https://api-matricula.qi.edu.br/${baseURL}`,
    headers: {
      'x-access-token': token,
    },
  })
  return instance
}

export default class{

  constructor() {
    this.instance = https('wellcome/')
  }

  getToken = (ra, idTurmaDisc) => {
    return this.instace({
      method: 'GET',
      url: `token/${idTurmaDisc}/${ra}`,
    })
  }

  getLinks = (idTurmaDisc) => {
    return this.instance({
      method: 'GET',
      url: `links/${idTurmaDisc}`,
    })
  }

  sendMessage = (token, reply, note, source) => {
    return this.instance({
      method: 'POST',
      timeout: 30000,
      data: {
        token,
        reply,
        note,
        source
      },
    })
  }
}